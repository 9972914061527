import * as serviceWorkerRegistration from './serviceWorkerRegistration';

console.log('window.location', window.location)

console.log('process.env', process.env)

function redirect() {
  if (!process.env.REACT_APP_REDIRECT_DOMAIN) {
    window.location.href = 'https://powertree.ai'
    return
  }

  if (window.location.pathname.includes('/links/')) {
    window.location.href = `${process.env.REACT_APP_REDIRECT_DOMAIN}${window.location.pathname}`
  } else {
    window.location.href = process.env.REACT_APP_REDIRECT_DOMAIN
  }
}

redirect()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
